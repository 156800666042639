import { Component, OnInit, Input, Inject, OnDestroy, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { Subject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { takeUntil, map, finalize } from 'rxjs/operators';

import { UserSummary } from '../../shared/types/user-summary.interface';
import { AccountUsersApiService} from '../../shared/services/account-users.api.service';
import { Permissions, SitesApiService } from '../../shared/services/sites.api.service';
import { Role } from '../../shared/types';
import { EditorState} from '../../shared/components/types/editor-state.type';

import { SelectableOption } from '../../shared/dynamic-form/form.interface';
import { AutocompleteOptionGroup } from '../../shared/components/autocomplete-item/autocomplete-option-group.interface';
import { EMAIL_REGEXP } from '../../shared/utils/email-regexp.const';
import { AlertService } from '../../shared/services/alert.service';
import { PageService } from '../../shared/services/page.service';


interface UserSuggestion {
   groupName: string;
   children: UserSummary[];
}

@Component({
   selector: 'bb-add-users',
   templateUrl: './add-users.component.html',
   styleUrls: ['./add-users.component.scss',],
})
export class AddUsersComponent implements OnInit, OnDestroy {
   @ViewChild(MatTable) columnsTable: MatTable<UserSummary[]>;
   public displayedColumns = ['username', 'fullname', 'role', 'delete'];
   public accountUsers: UserSummary[];
   public selectableRoles: Role[];
   public roles: Role[];
   public sitePermissions: Permissions;
   public filteredOptions$: Observable<AutocompleteOptionGroup[]>;
   public userSuggestions: UserSuggestion[] = [];
   public state: EditorState;
   public dataSource: UserSummary[] = [];
   public saveEnabled: boolean;
   public usernameControl: FormControl;

   private destroy$ = new Subject<void>();
   private subscription: Subscription;
   private page: Page;

   constructor(
      @Inject('User') private currentUser: User,
      private pageService: PageService,
      private alertService: AlertService,
      private accountUsersApiService: AccountUsersApiService,
      private sitesApiService: SitesApiService,
   ) {
      this.state = 'loading';
      this.page = this.pageService.page;
   }


   ngOnInit() {
      forkJoin({
         accountUsers: this.accountUsersApiService.getAccountUsers(this.page.accountId),
         roles: this.accountUsersApiService.getAccountRoles(this.page.siteId),
         sitePermissions: this.sitesApiService.fetchPermissions(this.page.siteId),
      })
         .pipe(
            this.alertService.notifyOnError('loading users'),
            takeUntil(this.destroy$),
         )
         .subscribe(({accountUsers, roles, sitePermissions}) => {
            this.accountUsers = accountUsers;
            this.roles = roles;
            this.selectableRoles = roles.filter(role => role.isManager === false);
            this.sitePermissions = sitePermissions;
            this.usernameControl = new FormControl('', { validators: [this.usernameValidator()]});
            this.state = 'ready';
            this.saveEnabled = false;
         });
      }

   public autocompleteFormChange(value: string): void {
      if (!value || value.length < 2) {
         this.userSuggestions.splice(0);
         this.filteredOptions$ = this.getFilteredOptions(value);
      } else if (EMAIL_REGEXP.test(value)) {
         this.subscription?.unsubscribe();
         this.subscription = this.accountUsersApiService.getAccountInvites(this.page.accountId)
            .pipe(
               this.alertService.notifyOnError('loading account invites'),
               takeUntil(this.destroy$),
            )
            .subscribe(accountInvites => {
               const emailInvite: UserSummary[] = [];
               const alreadyInvited = this.dataSource.some(elm => elm.username === value) || accountInvites.some(elm => elm.email === value);
               emailInvite.push({ username: value, id: '', roleId: this.getRoleId('editor'), ...(alreadyInvited) && {additionalInfo: 'already invited', disabled: true}});
               this.userSuggestions.splice(0);
               this.userSuggestions.push({ groupName: '', children: emailInvite});
               this.filteredOptions$ = this.getFilteredOptions(value);
            });
         }
         else {
            this.sitesApiService.getSiteUsers(this.page.siteId, value)
               .pipe(
                  this.alertService.notifyOnError('loading site users'),
                  takeUntil(this.destroy$),
               )
               .subscribe(siteUsers => {
                  // Add current user to list of results (enables Cloud Managers to add themselves across sites)
                  if (!siteUsers.find(user => user.id === this.currentUser.id)) {
                     if (this.textIncludesQuery(this.currentUser.fullName, value) || this.textIncludesQuery(this.currentUser.username, value)) {
                        const user: UserSummary = { id: this.currentUser.id, fullname: this.currentUser.fullName, username: this.currentUser.username};
                        siteUsers.unshift(user);
                     }
                  }
                  // filter out any users we are already adding/changing
                  siteUsers = siteUsers.filter(user => !this.dataSource.find(newUser => newUser.id === user.id));
                  // Fill in the previous role for users already on the account (accountUsers includes managers too)
                  // and disable if user already has an assigned role
                  siteUsers.forEach(user => {
                     const roleId = this.getAlreadyRoleId(user);
                     if (roleId) {
                        user.additionalInfo = "already ".concat(this.getRoleName(roleId));
                        user.disabled = true;
                     }
                  });
                  this.userSuggestions.splice(0);
                  this.userSuggestions.push({ groupName: '', children: siteUsers});

                  this.filteredOptions$ = this.getFilteredOptions(value);
               });
         }
   }

   public onSelectionChanged(value: string) {
      this.userSuggestions.forEach(element => {
         element.children.forEach(child => {
            if (child.username === value || child.fullname === value) {
               this.dataSource.push({id: child.id, username: child.username, fullname: child.fullname, roleId: this.getRoleId('editor')});
               this.refresh();
               this.saveEnabled = true;
            }
         });
      });
   }

   public removeColumn(atIndex: number): void {
      this.dataSource.splice(atIndex, 1);
      if ( !this.dataSource.length ) {
         this.saveEnabled = false;
      }
      this.refresh();
   }

   ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
   }

   public save(): void {
      const requestList = [];
      const emailUsers: UserSummary[] = [];
      this.dataSource.forEach(elm => {
         if (elm.id || !this.emailTest(elm.username)) {
            requestList.push(this.accountUsersApiService.updateUserAccess(this.page.accountId, elm));
         }
         else {
            emailUsers.push({email: elm.username, roleId: elm.roleId, username: ''});
         }
      });
      if (emailUsers.length) {
         requestList.push(this.accountUsersApiService.inviteUsers(this.page.accountId, emailUsers));
      }
      forkJoin(requestList)
         .pipe(
            this.alertService.notifyOnError('updating users'),
            finalize(() => this.state = 'ready'),
            takeUntil(this.destroy$),
         )
         .subscribe(() => {
            this.alertService.show({
               text: 'User updates completed',
               type: 'success',
            });
            this.dataSource.splice(0);
            this.refresh();
            this.saveEnabled = false;
            // we need to actively refresh the main page to have the new added users displayed. This is until the completion of CC-211, CC-212
            location.reload();
         });
      }

   public discardChanges(): void {
      this.dataSource.splice(0);
      this.refresh();
      this.saveEnabled = false;
   }

   public getErrorMessage(): string {
      if (this.usernameControl.hasError('invalidName')) {
         return 'This username is already linked to the account';
      }
   }

   public createUsername(): void {
      if (!this.getErrorMessage()) {
         const name = this.usernameControl.value;
         this.dataSource.push({id: '', username: name, roleId: this.getRoleId('editor')});
         this.refresh();
         this.saveEnabled = true;
         this.usernameControl.reset();
      }
   }

   public getRoleId(roleName: string): string | null {
      const role = this.roles.find(obj => obj.name.toLowerCase() === roleName.toLowerCase());
      return role?.id;
   }

   public emailTest(name: string): boolean {
      return EMAIL_REGEXP.test(name);
   }

   private getFilteredOptions(value: string): Observable<AutocompleteOptionGroup[]> {
      return of(this.userSuggestions)
         .pipe(
            map(suggestions => suggestions.map(suggestion => this.filterGroup(suggestion, value)))
         );
   }

   private usernameValidator(): ValidatorFn {
      return (control: AbstractControl) => {
         return this.usernameInAccounts(control.value)
            ? { invalidName: true }
            : null;
      };
   }

   private usernameInAccounts(username: string): boolean {
      return this.accountUsers.find(user => user.username === username || user.fullname === username ) ? true: false;
   }

   private refresh(): void {
      this.columnsTable?.renderRows();
   }

   private filterChildren(child: UserSummary, value: string): SelectableOption {
      if (child.username?.toLowerCase().includes(value) || child.fullname?.toLowerCase().includes(value)) {
         return {
            value: child.username,
            preLabel: this.emailTest(child.username) ? 'Send invite to: ' : null,
            label: child.fullname,
            disabled: child.disabled,
            additionalInfo: child.additionalInfo,
         };
      }
   }

   private filterGroup(suggestion: UserSuggestion, value: string): AutocompleteOptionGroup {
      value = value.toLowerCase();
      const filteredChildren = suggestion.children
         .map(child => this.filterChildren(child, value))
         .filter(vl => !!vl);
      return {
         groupName: suggestion.groupName,
         children: filteredChildren,
      };
   }

   private getAlreadyRoleId(user: UserSummary): string | null {
      const already = this.accountUsers.find(userAccount => userAccount.username === user.username);
      return already?.roleId;
   }

   private getRoleName(roleId: string): string | null {
      const role = this.roles.find(obj => obj.id === roleId );
      return role?.name;
   }

   private textIncludesQuery(text: string, query: string): boolean {
      return text && text.toLowerCase().includes(query.toLowerCase());
   }
}

